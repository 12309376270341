import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2022/federal-seminar/1.jpg';
import blogd2 from '../../assets/images/2022/federal-seminar/2.jpg';
import blogd3 from '../../assets/images/2022/federal-seminar/3.jpg';
import blogd4 from '../../assets/images/2022/federal-seminar/4.jpg';
import blogd5 from '../../assets/images/2022/federal-seminar/5.jpg';
import blogd6 from '../../assets/images/2022/federal-seminar/6.jpg';
import blogd7 from '../../assets/images/2022/federal-seminar/7.jpg';
import blogd8 from '../../assets/images/2022/federal-seminar/8.jpg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  Federal Ministry of Health Held Seminar in LUTH: Patients to
                  Access Affordable Surgical Care
                </h2>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  The Department of Hospital Services, Federal Ministry of
                  Health has informed a cross section of members of staff drawn
                  from various Medical Personnel working in LUTH, that the time
                  was ripe for patients to access improved quality and
                  affordable Surgical Care in the Hospital. This was made known
                  on Wednesday 13th July, 2022 by the representatives of the
                  FMOH, Abuja, during an enlightenment training programme
                  organized by the Ministry in collaboration with the Hospital
                  Management.
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Consultative Services Ongoing at the Outreach
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Members of the Hospital Services, FMOH present at the
                  programme include:<br></br> • Dr Deborah Bitrus-Oghoghorie
                  (Consultant Grade 1)- Lead Team Speaking, the Team Lead, Dr
                  Deborah Bitrus-Oghoghorie stated that the National Surgical,
                  Obstetrics, Anesthesia and Nursing Plan (NSOANP) with its
                  roadmap will be carrying out its Policy down to various States
                  of the Federation with bid to disseminating its plan in all
                  the 36 States so that Nigerians will know what it entails.
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  An attendee making a comment at the seminar
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  She said that NSOANP had to do with providing affordable,
                  accessible and safe surgery to Nigerians that need it as part
                  of Universal Health Coverage. “This is as a result of World
                  Health Assembly Resolution in 2015 which mandated Member
                  States to include basic and Surgical Care as part of Universal
                  coverage in the country. “Federal Ministry of Health produced
                  the plan and most of the implementation takes place at the
                  State level. “That is why we started the dissemination to the
                  36 States and the Federal Capital Territory after approval was
                  given at the National Council of Health,”. She said that World
                  Health Assembly Resolution 68:15 says Surgical, Obstetrics,
                  Anesthesia and Nursing care are important components of
                  Universal Health Coverage, therefore, Surgeries are fully
                  integrated into Healthcare Services.
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Sections of the Seminar
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  More pictures
                </span>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Community Health Department</a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Medical Outreach to Mashewele Community  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
